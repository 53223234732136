import {LitElement, html, customElement} from '../../node_modules/lit-element';

@customElement('nonstop-about')
export class AboutElement extends LitElement {

    protected override createRenderRoot() {
        return this;
    }
    
    override render() {
        return html`
            <div class="about-box">
                <div class="container">
                    <div class="row">
                    </div>

                    <hr class="hr1">

                    <div class="row">
                        <div class="col-md-6">
                            <div class="post-media wow fadeIn" style="visibility: visible; animation-name: fadeIn;">
                                <img src="../../uploads/about_bg.jpg" alt="" class="img-responsive">
                            </div><!-- end media -->
                        </div>
                        <div class="col-md-6">
                            <div class="message-box right-ab">
                                <!--                    <h4>Award Winning Real Estate Company</h4>-->
                                <h2>Welcome...</h2>
                                <p>Whatever your circumstances and real estate goals, we can help you achieve and exceed your expectations.</p>
                                <p>With our team of in-house and on-call professionals you're in good hands, and can rest easy while the hard work is handled for you without fuss. </p>
                                <p>We provide a range of services including sales and property management. Reach out now to see how we can help you!</p>
<!--                                <a href="#" class="btn btn-light btn-radius grd1 btn-brd"> Read More </a>-->
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'nonstop-about': AboutElement;
    }
}
